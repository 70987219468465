import React from "react"

import { graphql, navigate } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import Stage from "../../components/Stage"

import Grid from "../../components/Grid"
import SafeAreaWrapper from "../../components/SafeAreaWrapper"
import ScheduleDemoForm from "../../components/ScheduleDemoForm"

import ChecklistItem from "../../components/ChecklistItem"

// const slides = Array.from({ length: 1 }).map((_, index) => (
// 	<Container key={`slides-${index}`}>
// 		<div className="grid grid-cols-homepage-hero gap-y-50 gap-x-20 pt-160 pb-80 md:gap-x-40">
// 			<div className="col-span-12 flex h-full max-w-[600px] flex-col justify-center sm:items-center md:col-start-2 md:col-end-12 md:max-w-none lg:col-span-6 lg:items-start xl:col-span-5 xl:col-start-2 xl:mr-30">
// 				<h1 className="display1 text-white sm:max-w-[600px] sm:text-center lg:pr-30 lg:text-left">
// 					Everything in one system with Cloud Fleet Manager
// 				</h1>
// 			</div>

// 			<div className="col-span-12 flex h-full flex-col justify-center lg:col-span-6">
// 				<img
// 					alt="Cloud Fleet Manager running on devices"
// 					src={cfmOnDevices}
// 					className="w-full"
// 				/>
// 			</div>
// 		</div>
// 	</Container>
// ))

// backgroundImage, bannerImage
const DemoPage = ({ data: { cfmOnDevicesImage, customers } }) => {
	return (
		<Layout translucent={true}>
			<Seo
				title="Cloud Fleet Manager"
				description="Get your first-hand impressions of our system and schedule your demo of Cloud Fleet Manager."
			/>

			{/* <Stage name="hero" className="pt-[60px] md:pt-[80px] h-[500px]">
				<Grid>
					<div className="col-span-12 lg:col-span-5 lg:col-start-2">
						<div className="my-[108px]">
							<h1 className="display1 text-left text-white">
								Everything in one system with Cloud Fleet Manager
							</h1>
						</div>
					</div>
					<div className="col-span-12 lg:col-span-6 relative"></div>
				</Grid>
			</Stage> */}

			<Stage name="hero" className="overflow-visible min-h-[500px]">
				<div className="xl:container px-10 xl:px-30">
					<div className="px-10 md:px-20">
						<div className="pt-[160px] pb-[80px]">
							<div className="grid grid-cols-12 gap-x-20 md:gap-x-40 gap-y-50">
								<div className="col-span-12 md:col-start-2 md:col-end-12 lg:col-span-6 xl:col-span-5 xl:col-start-2 flex flex-col items-center lg:items-start max-w-[600px] md:max-w-none xl:mr-30 self-center">
									<h1 className="display1 text-white xl:pr-30 sm:text-center lg:text-left md:mt-[50px]">
										Everything in one system with Cloud Fleet Manager
									</h1>
									<p className="text-white mt-20 mb-30 sm:text-center lg:text-left">
										All-in-one software for shipping companies that works
										entirely in web-browsers.
									</p>
								</div>

								<div className="col-span-12 lg:col-span-6 flex flex-col justify-center">
									<div className="lg:absolute">
										<GatsbyImage
											alt="Cloud Fleet Manager running on devices"
											loading="eager"
											image={getImage(cfmOnDevicesImage)}
											className="lg:h-[350px] xl:h-[450px] z-10"
											imgStyle={{
												objectPosition: "left",
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Stage>

			<SafeAreaWrapper>
				<div className="bg-white my-spacer-xl">
					<Grid className="gap-y-40">
						<div className="col-span-12 lg:col-span-6 relative lg:order-2">
							<div className="relative lg:-top-[30px]">
								<ScheduleDemoForm
									className="bg-porcelain p-spacer-sm rounded-medium"
									onSubmitHandler={() => {
										navigate("/demo/thank-you")
									}}>
									<p className="bodyp text-base text-jetblack">
										To guarantee the best customer experience, one of our local
										representatives will get in touch within three working days
										to assess your company’s current situation and requirements.
										Additional and more in-depth information will be provided.
									</p>
									<p className="bodyp my-spacer-sm text-base text-jetblack">
										After identifying the solutions relevant to you, an onsite
										or remote demo will be scheduled to give you an
										understanding of what Cloud Fleet Manager can do for your
										company.
									</p>
								</ScheduleDemoForm>
							</div>
						</div>

						<div className="col-span-12 lg:col-span-5 lg:col-start-2">
							<h3 className="display3 mb-spacer-sm">We cover all your needs</h3>
							<ul className="">
								{[
									"Worldwide access",
									"Flexible expansion",
									"Data transfer from external systems",
									"Integration of the fleet into all processes",
									"Integration of your existing systems",
									"Access to Cloud Fleet Manager interfaces (API)",
									"Software as a Service (SaaS)",
									"Solutions for all your departments",
								].map((element, index) => (
									<li key={`checklistitem-${index}`} className="my-20">
										<ChecklistItem label={element} />
									</li>
								))}
							</ul>
							{/* <h3 className="mt-spacer-lg mb-spacer-sm display3">
								First-class companies count on us
							</h3>
							<div className="flex flex-row flex-wrap gap-40 justify-between mr-spacer-sm">
								{customers.edges
									.sort(() => 0.5 - Math.random())
									.slice(0, 3)
									.map(({ node }, index) => {
										return (
											<img
												loading="lazy"
												src={node.logo?.localFile.publicURL}
												title={node.name}
												alt={node.name}
												height="30"
												className="h-30 saturate-0"
												key={`customer-logo-${index}`}
											/>
										)
									})}
							</div> */}
						</div>
					</Grid>
				</div>
			</SafeAreaWrapper>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		customers: allStrapiCustomer(
			filter: { emphasize: { eq: true }, logo: { mime: { eq: "image/svg+xml" } } }
		) {
			edges {
				node {
					name
					logo {
						alternativeText
						localFile {
							publicURL
						}
					}
				}
			}
		}

		cfmOnDevicesImage: file(relativePath: { eq: "cfm-on-devices.png" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 450, placeholder: NONE)
			}
		}
	}
`

export default DemoPage
